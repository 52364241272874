import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, provideRouter } from '@angular/router';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { Store } from '@ngrx/store';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { LocalStorageStoreModule } from 'local-storage';
import { CategoriesStoreModule } from 'rio-categories-data-access';
import {
  API_PREFIX,
  ConfigService,
  OrganizationsService,
  OrganizationsStoreModule,
  RouterStoreModule,
  SystemStoreModule,
  ToastStoreModule,
  UserService,
  UserStoreModule,
  configProvider,
} from 'rio-core';
import { PromptsStoreModule } from 'rio-prompts-data-access';
import { RioTrackingStoreModule, TrackingService } from 'rio-tracking';
import { rioUserInitializer } from '../initializers/rio-user-initializer';
import {
  SentryErrorHandlerService,
  initializeSentry,
} from '../initializers/sentry-initializer';
import { TioRewriteStoreModule } from '../initializers/tio-rewrite-store-module';
import { TioRewriteTranslateModule } from '../initializers/tio-rewrite-translate.module';
import { APIErrorHandlerInterceptor } from '../interceptors/api-error-handler.interceptor';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    importProvidersFrom(TioRewriteStoreModule),
    importProvidersFrom(UserStoreModule),
    importProvidersFrom(SystemStoreModule),
    importProvidersFrom(OrganizationsStoreModule),
    importProvidersFrom(TioRewriteTranslateModule),
    importProvidersFrom(KeycloakAngularModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(LocalStorageStoreModule),
    importProvidersFrom(ToastStoreModule),
    importProvidersFrom(CategoriesStoreModule),
    importProvidersFrom(PromptsStoreModule),
    importProvidersFrom(RouterStoreModule),
    importProvidersFrom(RioTrackingStoreModule),
    provideHotToastConfig(),
    provideHttpClient(),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializeSentry,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: rioUserInitializer,
      multi: true,
      deps: [
        KeycloakService,
        Store,
        OrganizationsService,
        UserService,
        TrackingService,
        HttpClient,
        Router,
      ],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configProvider,
      multi: true,
      deps: [ConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: API_PREFIX,
      useValue: '/api/v1',
    },
  ],
};
