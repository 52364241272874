import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

function translateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({ imports: [TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoader,
                deps: [HttpClient],
            },
        })], providers: [HttpClient, provideHttpClient(withInterceptorsFromDi())] })
export class TioRewriteTranslateModule {}
